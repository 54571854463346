import { createSelector } from 'reselect';
import type { IToggle } from 'unleash-proxy-client';

import type { AppState } from 'types/app';
import {
  FEATURE_BANNER_ADS,
  FEATURE_CALYPSO_AKAMAI_CALL,
  FEATURE_ENABLED_VARIANT_NAME_TREATMENT,
  FEATURE_HOMEPAGE_BANNER_ADS,
  FEATURE_MAFIA_AKAMAI,
  FEATURE_MARTY_CLIENT_RENDER,
  FEATURE_PDP_ACCORDION_ORDER,
  FEATURE_PDP_PAPER_CUTS,
  FEATURE_ZCS_DIRECT_CALL
} from 'constants/features';
import { TREATMENT_AFTERPAY_REWORDING } from 'hooks/unleash/useFeaturePdpPaperCuts';
import type { SignUpDrawerVariant, SignUpDrawerVariantSelector } from 'components/common/SignUpDrawer/SignUpDrawer.types';
import { DESKTOP_MAXIMIZED_MOBILE_MINIMIZED, DESKTOP_MOBILE_MAXIMIZED, DESKTOP_MOBILE_MINIMIZED } from 'constants/emailSignupDrawer';

export const selectUnleashToggle = (feature: string, state: AppState): IToggle | undefined =>
  (state.unleash?.toggles || []).find((t: IToggle) => t.name === feature);

export const selectABTestEnabled = (testName: string, state: AppState, treatmentValue = '1') =>
  selectUnleashVariantByPayload(testName, state) === treatmentValue;

export const selectIsFeatureMafiaAkamai = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MAFIA_AKAMAI, state)?.enabled);

export const selectIsFeatureCalypsoAkamai = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_CALYPSO_AKAMAI_CALL, state)?.enabled);

export const selectIsFeatureMartyClientRender = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MARTY_CLIENT_RENDER, state)?.enabled);

export const selectIsFeatureBannerAds = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_BANNER_ADS, state)?.enabled);

export const selectIsFeatureHomepageBannerAds = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_HOMEPAGE_BANNER_ADS, state)?.enabled);

export const selectIsPdpAccordionOrder = (state: AppState) =>
  Boolean(selectUnleashToggle(FEATURE_PDP_ACCORDION_ORDER, state)?.variant.name === FEATURE_ENABLED_VARIANT_NAME_TREATMENT);

export const selectIsZCSDirect = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_ZCS_DIRECT_CALL, state)?.enabled);

const selectPdpPaperCutsVariant = (state: AppState) => selectUnleashToggle(FEATURE_PDP_PAPER_CUTS, state)?.variant?.name;

export const selectIsFeaturePdpPaperCuts = createSelector([selectPdpPaperCutsVariant], variant => ({
  isTreatmentPdpStyleUpdatesEnabled: variant === 'treatment',
  isTreatmentAfterpayRewordingEnabled: variant === TREATMENT_AFTERPAY_REWORDING
}));

export const selectUnleashVariantByPayload = (feature: string, state: AppState) => selectUnleashToggle(feature, state)?.variant.payload?.value;

export const selectIsEmailDrawerSignup = (feature: string, unleashList?: { toggles?: IToggle[] }): SignUpDrawerVariantSelector => {
  if (!unleashList?.toggles) {
    return { isEnabled: false, variantVersion: 'control' };
  }
  const toggle = unleashList.toggles.find((t: IToggle) => t.name === feature);

  const variantName = toggle?.variant.name;
  const acceptedVariant =
    variantName === DESKTOP_MAXIMIZED_MOBILE_MINIMIZED || variantName === DESKTOP_MOBILE_MAXIMIZED || variantName === DESKTOP_MOBILE_MINIMIZED;
  const variantVersion: SignUpDrawerVariant = acceptedVariant ? variantName : 'control';

  const isEnabled = Boolean(toggle?.enabled && acceptedVariant);

  return { isEnabled, variantVersion };
};
